import React from 'react';
import { Row, Col, Button, Container } from 'reactstrap';
class CampaignProduct extends React.Component {
    constructor() {
        super();
        this.state = {
            tabActive: 1
        };
        this.toggleTab1 = this.toggleTab1.bind(this);
        this.toggleTab2 = this.toggleTab2.bind(this);
    };
    toggleTab1 = () => {
        this.setState({
            tabActive: 1
        })
    }
    toggleTab2 = () => {
        this.setState({
            tabActive: 2
        })
    }
    render() {
        return (
            <Container fluid={true}>
                <div className="campaign-product">
                    <ul className="campaign-multiple">
                        <li><Button color="nothing"></Button></li>
                        <li><Button color="nothing" className="active" style={{ backgroundImage: "url('/images/duke.png')" }}></Button></li>
                        <li><Button color="nothing"></Button></li>
                        <li><Button color="nothing"></Button></li>
                        <li><Button color="nothing"></Button></li>
                    </ul>
                    <div className="campaign-details">
                        <Row>
                            <Col md="5">
                                <h5>Stand a chance to win</h5>
                                <h3>KTM DUKE 390</h3>
                                <div className="product-img">
                                    <img src="/images/duke.png" alt="" className="img-fluid" />
                                </div>
                                <p>Maximum draw date : <b>24 Dec 2020</b></p>
                                <p>or the campaign is sold out. whichever is earlier</p>
                            </Col>
                            <Col md="3">
                                <div className="number-circle1">
                                    <div className="number-svg">
                                        <svg width="180" height="180" xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <defs>
                                                    <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                                                        <stop offset="5.64%" stopColor="#811fff" />
                                                        <stop offset="77.66%" stopColor="#d10aff" />
                                                    </linearGradient>
                                                </defs>
                                                <circle strokeLinecap="round" strokeDasharray="440"
                                                    strokeDashoffset="300" stroke="url(#linear)"
                                                    className="circle_animation" r="80" cy="90" cx="90" strokeWidth="10" fill="none" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="number-text">
                                        <h2>650</h2>
                                        <h6>SOLD OUT OF</h6>
                                        <h5>2000</h5>
                                    </div>
                                </div>
                            </Col>
                            <Col md="4">
                                <h5>Buy A</h5>
                                <h3>HORUS CAP</h3>
                                <div className="product-img">
                                    <img src="/images/horus_cap.png" alt="" className="img-fluid" />
                                </div>
                                <h3>$599.00</h3>
                                <div className="">
                                    <Button color="primary">Add to cart</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Row>
                    <Col md={{ size: "8", offset: "2" }}>
                        <div className="campaign-tabs">
                            <ul>
                                <li>
                                    <Button color="nothing" onClick={this.toggleTab1} className={this.state.tabActive === 1 ? "active" : ""}>Campaign Description</Button>
                                </li>
                                <li>
                                    <Button color="nothing" onClick={this.toggleTab2} className={this.state.tabActive === 2 ? "active" : ""}>Product Description</Button>
                                </li>
                            </ul>
                            {this.state.tabActive === 1 &&
                                <div className="campaign-desc" >
                                    <h4>Description</h4>
                                    <p>iPad Pro Bundle includes (New iPad Pro 11 inch, Apple Magic Keyboard, Apple Pencil). The all new iPad Pro is the thinnest iPad ever, and it is packed with the most advanced technology. Meanwhile, Apple Magic Keyboard is durable and designed to deliver a great typing experience. Finally, the second‑generation of Apple Pencil will take your using experience even further as its designed to be an even more comfortable, natural tool to use.</p>
                                    <h4>Specification</h4>
                                    <p>iPad Pro Bundle includes (New iPad Pro 11 inch, Apple Magic Keyboard, Apple Pencil). The all new iPad Pro is the thinnest iPad ever, and it is packed with the most advanced technology. Meanwhile, Apple Magic Keyboard is durable and designed to deliver a great typing experience. Finally, the second‑generation of Apple Pencil will take your using experience even further as its designed to be an even more comfortable, natural tool to use.</p>
                                </div>
                            }
                            {this.state.tabActive === 2 &&
                                <div className="campaign-desc">
                                    <h4>Specification</h4>
                                    <p>iPad Pro Bundle includes (New iPad Pro 11 inch, Apple Magic Keyboard, Apple Pencil). The all new iPad Pro is the thinnest iPad ever, and it is packed with the most advanced technology. Meanwhile, Apple Magic Keyboard is durable and designed to deliver a great typing experience. Finally, the second‑generation of Apple Pencil will take your using experience even further as its designed to be an even more comfortable, natural tool to use.</p>
                                    <h4>Description</h4>
                                    <p>iPad Pro Bundle includes (New iPad Pro 11 inch, Apple Magic Keyboard, Apple Pencil). The all new iPad Pro is the thinnest iPad ever, and it is packed with the most advanced technology. Meanwhile, Apple Magic Keyboard is durable and designed to deliver a great typing experience. Finally, the second‑generation of Apple Pencil will take your using experience even further as its designed to be an even more comfortable, natural tool to use.</p>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default CampaignProduct