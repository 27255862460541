import React from 'react';
import { Col, Row } from 'reactstrap';
class PersonalDetailsCard extends React.Component {
    render() {
        return (
            <div className="card personal-details-card">
                <Row>
                    <Col md="7" className="d-flex align-items-center">
                        <div className="person-image" style={{ backgroundImage: "url('/images/profile.png')" }}></div>
                        <div>
                            <h3>Pranav Kumar</h3>
                            <p className="mb-0">googlemail@gmail.com</p>
                        </div>
                        <div className="clearfix"></div>
                    </Col>
                    <Col md="5" className="d-flex align-items-center justify-content-end">
                        <div className="wallet">
                            <h4 className="mb-0">6557 <span>Points</span><img src="/images/wallet.png" alt="" width="40" /></h4>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default PersonalDetailsCard