import React from 'react';
import { Row, Col, Button } from 'reactstrap';
class CartLeft extends React.Component {
    render() {
        return (
            <>
                <div className="cart-details">
                    <Row>
                        <Col md="5" className="d-flex align-items-center">
                            <Row>
                                <Col md="5">
                                    <img src="/images/horus_cap.png" alt="" className="img-fluid" />
                                </Col>
                                <Col md="7" className="d-flex flex-column justify-content-center">
                                    <h4 className="mb-0">Rs. 9850.00</h4>
                                    <p className="mb-0">Supreme Cap</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="7" className="d-flex align-items-center">
                            <Row>
                                <Col md="5">
                                    <img src="/images/car.png" alt="" className="img-fluid" />
                                </Col>
                                <Col md="7" className="d-flex flex-column justify-content-center">
                                    <h4 className="mb-0">Prize</h4>
                                    <p className="mb-0">Land Rover Defender 2020</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div className="cart-count">
                        <Button color="nothing" className="plus">+</Button>
                        <h5>9</h5>
                        <Button color="nothing" className="minus">-</Button>
                    </div>
                </div>
                <div className="cart-options">
                    <Button color="nothing">Move to Wishlist</Button>
                    <span className="mx-2">|</span>
                    <Button color="nothing">Remove from cart</Button>
                </div>
            </>
        )
    }
}
export default CartLeft