import React from 'react';
import { Container, Row, Col } from 'reactstrap';
class HomeApp extends React.Component {
    render() {
        return (
            <section className="home-app">
                <Container fluid={true}>
                    <Row>
                        <Col md="6">
                            <h2 className="home-heading">App for the better<br />shoping experience</h2>
                            <div className="app-links">
                                <p>For the ultimate shopping experience<br />download our app</p>
                                <p className="download">
                                    <a href="http://www.google.com">
                                        <img src="/images/apple.svg" height="30" alt="" />
                                        iOS
                                    </a>
                                </p>
                                <p className="download">
                                    <a href="http://www.google.com">
                                        <img src="/images/android.svg" height="30" alt="" />
                                        Android
                                    </a>
                                </p>
                            </div>
                        </Col>
                        <Col md="6">
                            <img src="/images/app.png" alt="" className="app-image" />
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}
export default HomeApp