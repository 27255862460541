import React from 'react';
import Header from '../../_components/header/header';
import BannerHome from '../../_components/banners/BannerHome';
import FeaturedCampaigns from '../../_components/sections/FeaturedCampaigns';
import HomeCategory from '../../_components/sections/HomeCategory';
import Footer from '../../_components/footer/footer';
import HomeWinners from '../../_components/sections/HomeWinners';
import HomeAllWinners from '../../_components/sections/HomeAllWinners';
import MapContact from '../../_components/sections/MapContact';
import HomeApp from '../../_components/sections/HomeApp';
import HomeCharities from '../../_components/sections/HomeCharities';
class Home extends React.Component {
    render() {
        return (
            <>
                <Header />
                <BannerHome />
                <FeaturedCampaigns />
                <HomeWinners />
                <HomeCategory />
                <HomeAllWinners />
                <HomeCharities />
                <MapContact />
                <HomeApp />
                <Footer />
            </>
        )
    }
}
export default Home