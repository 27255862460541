import React from 'react';
import { Button, Container } from 'reactstrap';
class BannerHome extends React.Component {
    render() {
        return (
            <div className="banner">
                <Container fluid={true}>
                    <div className="banner-text">
                        <h1>Shopping that leads<br />to your Dreams.</h1>
                        <Button color="white">Shop now</Button>
                    </div>
                </Container>
            </div>
        )
    }
}
export default BannerHome