import React from 'react';
import { Container, Row, Col } from 'reactstrap';
class MapContact extends React.Component {
    render() {
        return (
            <section className="map-contact">
                <Container fluid={true}>
                    <Row>
                        <Col md="6">
                            <h2 className="home-heading">Get in Touch</h2>
                            <h5>Monday - Saturday, 9:00 AM to 6:00 PM</h5>
                            <div className="map-bottom">
                                <h5>Socialise with us!</h5>
                                <p className="social-icons">
                                    <a href="https://www.facebook.com">
                                        <img src="/images/fb.svg" width="20" alt="" />
                                    </a>
                                    <a href="https://www.twitter.com">
                                        <img src="/images/twitter.svg" width="20" alt="" />
                                    </a>
                                    <a href="https://www.instagram.com">
                                        <img src="/images/insta.svg" width="20" alt="" />
                                    </a>
                                </p>
                                <Row>
                                    <Col lg="6">
                                        <h5>Write to us</h5>
                                        <p>MOD, 167 LLP, NR Colony, Basavanagudi, Bengaluru, Karnataka 560004.</p>
                                    </Col>
                                    <Col lg="3">
                                        <h5>Dial</h5>
                                        <p>1234567890</p>
                                    </Col>
                                    <Col lg="3">
                                        <h5>Mail</h5>
                                        <p>Support@mod.com</p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md="6">
                            <iframe title="Address" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d497699.9973874144!2d77.35074421903857!3d12.95384772557775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c9b44e6d%3A0xf8dfc3e8517e4fe0!2sBengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1602948906537!5m2!1sen!2sin"></iframe>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}
export default MapContact