import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import ShareSVG from '../svg/share-svg';
import FavSVG from '../svg/fav-svg';
import { NavLink } from 'react-router-dom';
class ProductCardFull extends React.Component {
    constructor() {
        super();
        this.state = {
            IsFavActive: false
        };
        this.favActive = this.favActive.bind(this);
    };
    favActive(e) {
        this.setState({
            IsFavActive: !this.state.IsFavActive
        })
    }
    render() {
        return (
            <div className="product-card-full">
                <div className="product-actions">
                    <h3><span className="available-dot"></span> Available</h3>
                    <p>
                        <Button color="nothing"
                            onClick={this.favActive}
                            className={this.state.IsFavActive ? "active" : ""}
                        ><FavSVG /></Button>
                        <Button color="nothing"><ShareSVG /></Button>
                    </p>
                </div>
                <NavLink to="/campaigns">
                    <div className="number-circle">
                        <div className="number-svg">
                            <svg width="160" height="160" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <defs>
                                        <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                                            <stop offset="5.64%" stopColor="#cc0033" />
                                            <stop offset="26.64%" stopColor="#fc3" />
                                            <stop offset="50.64%" stopColor="#fc3" />
                                            <stop offset="77.66%" stopColor="#2DC565" />
                                        </linearGradient>
                                    </defs>
                                    <circle
                                        // strokeLinecap="round" 
                                        strokeDasharray="440"
                                        strokeDashoffset="40"
                                        stroke="url(#linear)"
                                        className="circle_animation" r="70" cy="80" cx="80" strokeWidth="10" fill="none" />
                                </g>
                            </svg>
                        </div>
                        <div className="number-text">
                            <h2 className="home-heading">83%</h2>
                            <h3>SOLD</h3>
                        </div>
                    </div>
                    <div className="product-box">
                        <div className="featured-div">
                            <img src="/images/featured.png" alt="" />
                        </div>
                        <Row>
                            <Col md="4">
                                <div className="product-img">
                                    <div>
                                        <img src="/images/horus_cap.png" alt="" />
                                    </div>
                                </div>
                                <h4>Buy</h4>
                                <h4>Supreme Cap</h4>
                                <h3>Rs. 12,800</h3>
                            </Col>
                            <Col md="4">
                                <div className="product-center">
                                    <h3>1800</h3>
                                    <h5>OUT OF 2000</h5>
                                    <Button color="red">ADD to cart</Button>
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="product-img">
                                    <div>
                                        <img src="/images/car.png" alt="" />
                                    </div>
                                </div>
                                <h4>Get a chance to Win</h4>
                                <h4>Audi Q7</h4>
                                <h3>Rs. 80.95 lacs</h3>
                            </Col>
                        </Row>
                    </div>
                </NavLink>
            </div>
        )
    }
}
export default ProductCardFull