import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Slider from "react-slick";
import { NavLink } from 'react-router-dom';
class HomeAllWinners extends React.Component {
    render() {
        var settings = {
            arrows: true,
            dots: false,
            infinite: false,
            speed: 800,
            slidesToShow: 4,
            slidesToScroll: 1,
        };
        return (
            <section className="home-product">
                <Container fluid={true}>
                    <Row>
                        <Col sm="8">
                            <h2 className="home-heading">Products</h2>
                        </Col>
                        <Col sm="4" className="text-sm-right">
                            <h3 className="mt-3 mb-4"><NavLink to="">See All</NavLink></h3>
                        </Col>
                    </Row>
                    <Slider {...settings} className="home-product-slider">
                        <div className="home-product-card">
                            <div className="color1">
                                <img src="/images/product/notebook.png" alt="" className="img-fluid" />
                            </div>
                            <h5>Notebook</h5>
                        </div>
                        <div className="home-product-card">
                            <div className="color2">
                                <img src="/images/product/cap.png" alt="" className="img-fluid" />
                            </div>
                            <h5>Cap</h5>
                        </div>
                        <div className="home-product-card">
                            <div className="color3">
                                <img src="/images/product/hoodie.png" alt="" className="img-fluid" />
                            </div>
                            <h5>Hoodie</h5>
                        </div>
                        <div className="home-product-card">
                            <div className="color4">
                                <img src="/images/product/pencil.png" alt="" className="img-fluid" />
                            </div>
                            <h5>Pencil</h5>
                        </div>
                    </Slider>
                </Container>
            </section>
        )
    }
}
export default HomeAllWinners