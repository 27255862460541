import React from 'react';
import { NavLink } from 'react-router-dom';
import SearchSVG from '../svg/search-svg';
import CartSVG from '../svg/cart-svg';
import { Container } from 'reactstrap'
import NotificationSVG from '../svg/notification-svg';
import UserSVG from '../svg/user-svg';
class Header extends React.Component {
    constructor() {
        super();
        this.state = {
            headerScrolled: false
        };
        this.headerScroll = this.headerScroll.bind(this);
    };
    componentDidMount() {
        window.addEventListener('scroll', this.headerScroll)
    }
    headerScroll = () => {
        if (window.scrollY > 1) {
            this.setState({
                headerScrolled: true
            });
        }
        if (window.scrollY === 0) {
            this.setState({
                headerScrolled: false
            });
        }
    }
    render() {
        return (
            <>
                <header className={(this.state.headerScrolled ? "is-fixed" : "")}>
                    <Container fluid={true}>
                        <div className="header-left">
                            <div className="logo">
                                <NavLink to="/"><img src="/images/logo.png" alt="" /></NavLink>
                            </div>
                            <ul className="header-links">
                                <li>
                                    <NavLink to="/products">Products</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/campaigns-list">Campaigns</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="header-right">
                            <ul className="header-links">
                                <li className="notification">
                                    <NavLink to="/notification"><NotificationSVG /><span className="notification-dot"></span></NavLink>
                                </li>
                                <li className="search">
                                    <NavLink to="/search"><SearchSVG /></NavLink>
                                </li>
                                <li className="cart">
                                    <NavLink to="/cart"><CartSVG /><span className="cart-num">2</span></NavLink>
                                </li>
                                <li className="login">
                                    <NavLink to="/personal-details"><UserSVG />Login</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="clearfix"></div>
                    </Container>
                </header>
                <div className="header-margin"></div>
            </>
        )
    }
}
export default Header