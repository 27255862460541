import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Button, Row, Col } from 'reactstrap';
class HomeCharities extends React.Component {
    render() {
        return (
            <>
                <Container fluid={true}>
                    <h2 className="home-heading mt-5 mb-4">Charities We Support</h2>
                </Container>
                <section className="home-charities">
                    <Container fluid={true}>
                        <Row>
                            <Col md={{ size: "6" }}>
                                <img src="/images/charity.png" alt="" className="img-fluid" />
                            </Col>
                            <Col md={{ size: "6" }}>
                                <div className="charity-right">
                                    <img src="/images/ring.svg" alt="" width="100" />
                                    <h2 className="home-heading">Your donations are changing lives</h2>
                                    <NavLink to="/charities" className="charity-btn"><Button color="white">LEARN more</Button></NavLink>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}
export default HomeCharities