import React from 'react';
import { Button, Col, Row } from 'reactstrap';
class OrdersCard extends React.Component {
    render() {
        return (
            <>
                <h4 className="order-date">Ariving 7th,October</h4>
                <div className="card orders-card">
                    <Row>
                        <Col md="9">
                            <Row className="mb-3">
                                <Col md="3">
                                    <img src="/images/horus_cap.png" alt="" className="img-fluid" />
                                </Col>
                                <Col md="9" className="d-flex flex-column justify-content-center">
                                    <h4>Rs. 9850.00</h4>
                                    <p>Supreme Cap</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="3">
                                    <img src="/images/car.png" alt="" className="img-fluid" />
                                </Col>
                                <Col md="9" className="d-flex flex-column justify-content-center">
                                    <h4>Prize</h4>
                                    <p>Land Rover Defender 2020</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="3" className="d-flex align-items-center">
                            <Row>
                                <Col sm="12" className="mb-2">
                                    <Button color="red" block>Track Order</Button>
                                </Col>
                                <Col sm="12" className="mb-2">
                                    <Button color="red" block>Watch Draw</Button>
                                </Col>
                                <Col sm="12" className="mb-2">
                                    <Button color="red" block>Cancel Order</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}
export default OrdersCard