import React from 'react';
import Header from '../../_components/header/header';
import Footer from '../../_components/footer/footer';
import { Col, Row, Container } from 'reactstrap';
import LeftMenu from '../../_components/cards/LeftMenu';
import ActiveCouponsCard from '../../_components/cards/ActiveCouponsCard';
class Coupons extends React.Component {
    render() {
        return (
            <>
                <Header
                    className="is-fixed"
                />
                <Container fluid={true}>
                    <section className="dashboard-page">
                        <h2 className="dashboard-heading">Active Coupons</h2>
                        <Row>
                            <Col md={{ size: "9" }}>
                                <ActiveCouponsCard />
                            </Col>
                            <Col md={{ size: "3" }}>
                                <LeftMenu />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col md="4">
                                        <ActiveCouponsCard className="half" />
                                    </Col>
                                    <Col md="4">
                                        <ActiveCouponsCard className="half" />
                                    </Col>
                                    <Col md="4">
                                        <ActiveCouponsCard className="half" />
                                    </Col>
                                </Row>
                                <h2 className="dashboard-heading">Expired Coupons</h2>
                                <div className="expired-coupons">
                                    <Row>
                                        <Col md="4">
                                            <ActiveCouponsCard className="half" />
                                        </Col>
                                        <Col md="4">
                                            <ActiveCouponsCard className="half" />
                                        </Col>
                                        <Col md="4">
                                            <ActiveCouponsCard className="half" />
                                        </Col>
                                        <Col md="4">
                                            <ActiveCouponsCard className="half" />
                                        </Col>
                                        <Col md="4">
                                            <ActiveCouponsCard className="half" />
                                        </Col>
                                        <Col md="4">
                                            <ActiveCouponsCard className="half" />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </Container>
                <Footer />
            </>
        )
    }
}
export default Coupons