import React from 'react';
import { NavLink } from 'react-router-dom';
import RightArrowSVG from '../svg/right-arrow-svg';
class LeftMenu extends React.Component {
    render() {
        return (
            <div className="left-menu">
                <ul>
                    <li>
                        <NavLink to="/personal-details">Personal Details<span><RightArrowSVG /></span></NavLink>
                    </li>
                    <li>
                        <NavLink to="/wishlist">Wishlist<span><RightArrowSVG /></span></NavLink>
                    </li>
                    <li>
                        <NavLink to="/wallet">Wallet<span><RightArrowSVG /></span></NavLink>
                    </li>
                    <li>
                        <NavLink to="/coupons">Coupons<span><RightArrowSVG /></span></NavLink>
                    </li>
                    <li>
                        <NavLink to="/order-details">Order Details<span><RightArrowSVG /></span></NavLink>
                    </li>
                    <li>
                        <NavLink to="/settings">Settings<span><RightArrowSVG /></span></NavLink>
                    </li>
                </ul>
            </div>
        )
    }
}
export default LeftMenu