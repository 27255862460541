import React from 'react';
import Header from '../../_components/header/header';
import Footer from '../../_components/footer/footer';
import CampaignProduct from '../../_components/sections/CampaignProduct';
class Campaigns extends React.Component {
    render() {
        return (
            <>
                <Header
                    className="is-fixed"
                />
                <CampaignProduct />
                <Footer />
            </>
        )
    }
}
export default Campaigns