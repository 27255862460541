import React from 'react';
import { Input, Button } from 'reactstrap';
class CartRight extends React.Component {
    render() {
        return (
            <div className="cart-right-details">
                <div className="cart-table">
                    <h6>Total Products</h6>
                    <h6>6</h6>
                </div>
                <div className="cart-table">
                    <h6>Total Coupons</h6>
                    <h6>12</h6>
                </div>
                <hr />
                <div className="cart-table">
                    <h6>Redeem Wallet<br />Balance</h6>
                    <div>
                        <Input type="text" placeholder="Enter Points" />
                        <p className="mb-0">1500 Points Available</p>
                    </div>
                </div>
                <hr />
                <div className="cart-table">
                    <h6 className="mb-0">Donate and get<br />extra coupons!</h6>
                    <h6 className="mb-0">
                        <label className="switch donate">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                    </h6>
                </div>
                <hr />
                <div className="cart-table">
                    <h6>Product Cost</h6>
                    <h6>$2,968.00</h6>
                </div>
                <div className="cart-table">
                    <h6>Shipping Cost</h6>
                    <h6>$0.00</h6>
                </div>
                <div className="cart-table">
                    <h6>Wallet Discount</h6>
                    <h6>-$250.00</h6>
                </div>
                <hr />
                <div className="cart-table">
                    <h4>Grand Total</h4>
                    <h4>$37672.00</h4>
                </div>
                <div className="mt-2">
                    <Button color="red-light" block>Proceed to Payment</Button>
                </div>
            </div>
        )
    }
}
export default CartRight