import React from 'react';
class WalletCard extends React.Component {
    render() {
        return (
            <div className="card wallet-card">
                <div className="wallet-card-padding">
                    <img src="/images/wallet.png" className="wallet-image" alt="" width="40" />
                    <h3 className="mb-4">15000 Points</h3>
                    <div className="d-flex justify-content-between">
                        <p className="mb-1">Earned through purchases</p>
                        <p className="mb-2">0</p>
                    </div>
                    <hr className="mt-0 mb-2" />
                    <div className="d-flex justify-content-between">
                        <p className="mb-1">Earned through referals</p>
                        <p className="mb-2">0</p>
                    </div>
                    <hr className="mt-0 mb-2" />
                    <div className="d-flex justify-content-between">
                        <p className="mb-1">Total earned</p>
                        <p className="mb-2">0</p>
                    </div>
                    <hr className="mt-0 mb-2" />
                    <div className="d-flex justify-content-between">
                        <p className="mb-1">Spend</p>
                        <p className="mb-2">0</p>
                    </div>
                    <hr className="mt-0 mb-2" />
                </div>
            </div>
        )
    }
}
export default WalletCard