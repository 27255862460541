import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
class Footer extends React.Component {
    render() {
        return (
            <footer>
                <Container fluid={true}>
                    <Row>
                        <Col md="2">
                            <h4>Campaigns</h4>
                            <ul>
                                <li>
                                    <NavLink to="/about">About</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/personal-details">My Account</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/coupons">Active Coupons</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/campaigns">Campaigns</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/product">Product</NavLink>
                                </li>
                            </ul>
                        </Col>
                        <Col md="2">
                            <h4>Quicklinks</h4>
                            <ul>
                                <li>
                                    <NavLink to="/cash">Cash</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/lifestyle">Lifestyle</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/auto">Auto</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/watches">Watches</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/electronics">Electronics</NavLink>
                                </li>
                            </ul>
                        </Col>
                        <Col md="4">
                            <h4>Customer Service</h4>
                            <ul>
                                <li>
                                    <NavLink to="/contact-us">Contact Us</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/faqs">FAQs</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/how-we-works">How we works</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/charities">Charities</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/terms-condition">Terms & Condition</NavLink>
                                </li>
                            </ul>
                        </Col>
                        <Col md="4" className="border-left">
                            <div className="social-media">
                                <h5>Socialise with us!</h5>
                                <p className="social-icons">
                                    <a href="https://www.facebook.com">
                                        <img src="/images/fb.svg" width="20" alt="" />
                                    </a>
                                    <a href="https://www.twitter.com">
                                        <img src="/images/twitter.svg" width="20" alt="" />
                                    </a>
                                    <a href="https://www.instagram.com">
                                        <img src="/images/insta.svg" width="20" alt="" />
                                    </a>
                                </p>
                                <p>For the ultimate shopping experience<br />download our app.</p>
                                <p className="download">
                                    <a href="http://www.google.com">
                                        <img src="/images/apple.svg" height="30" alt="" />
                                        iOS
                                    </a>
                                </p>
                                <p className="download">
                                    <a href="http://www.google.com">
                                        <img src="/images/android.svg" height="30" alt="" />
                                        Android
                                    </a>
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <div className="footer-copyright">
                        <ul>
                            <li><img src="/images/logo.png" alt="" /></li>
                            <li>&copy; 2020. All rights reserved</li>
                            <li><NavLink to="/user-agreement">User Agreement</NavLink></li>
                            <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
                        </ul>
                    </div>
                </Container>
            </footer>
        )
    }
}
export default Footer