import React from 'react';
class CartSVG extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="35.108" height="30.24" viewBox="0 0 35.108 30.24">
                <g id="Cart_Icon" data-name="Cart Icon" transform="translate(1.5 1.5)">
                    <path id="Path_16" data-name="Path 16" d="M0,0H23.727L19.272,16.007H4.373L2.366,8.662Z" transform="translate(8.381 4.616)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                    <path id="Path_17" data-name="Path 17" d="M1510.839,85.025l-5.819-19.568h-6.87" transform="translate(-1498.15 -65.457)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3" />
                    <g id="Ellipse_33" data-name="Ellipse 33" transform="translate(24.688 23.74)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-width="3">
                        <circle cx="2.5" cy="2.5" r="2.5" stroke="none" />
                        <circle cx="2.5" cy="2.5" r="1" fill="none" />
                    </g>
                    <g id="Ellipse_34" data-name="Ellipse 34" transform="translate(11.688 23.74)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-width="3">
                        <circle cx="2.5" cy="2.5" r="2.5" stroke="none" />
                        <circle cx="2.5" cy="2.5" r="1" fill="none" />
                    </g>
                </g>
            </svg>


        )
    }
}
export default CartSVG