import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import Home from './_pages/home/home';
import Cart from './_pages/cart/cart';
import PersonalDetails from './_pages/personal-details/personal-details';
import Wishlist from './_pages/wishlist/wishlist';
import Campaigns from './_pages/campaigns/campaigns';
import Coupons from './_pages/coupons/coupons';
import CampaignsList from './_pages/campaigns-list/campaigns-list';
import Settings from './_pages/settings/settings';
import Wallet from './_pages/wallet/wallet';
import Orders from './_pages/orders/orders';
import HowWeWork from './_pages/how-we-work/how-we-work';
import About from './_pages/about/about';
import Charities from './_pages/charities/charities';
import ContactUs from './_pages/contact-us/contact-us';

function App() {
  return (
    <div className="App">
      <Router>
        <>
          <Route exact path="/" component={Home} />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/personal-details" component={PersonalDetails} />
          <Route exact path="/wishlist" component={Wishlist} />
          <Route exact path="/campaigns" component={Campaigns} />
          <Route exact path="/campaigns-list" component={CampaignsList} />
          <Route exact path="/coupons" component={Coupons} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/wallet" component={Wallet} />
          <Route exact path="/order-details" component={Orders} />
          <Route exact path="/how-we-works" component={HowWeWork} />
          <Route exact path="/about" component={About} />
          <Route exact path="/charities" component={Charities} />
          <Route exact path="/contact-us" component={ContactUs} />
        </>
      </Router>
    </div>
  );
}

export default App;
