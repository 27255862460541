import React from 'react';
import Header from '../../_components/header/header';
import Footer from '../../_components/footer/footer';
import { Col, Row, Container } from 'reactstrap';
import LeftMenu from '../../_components/cards/LeftMenu';
import OrdersCard from '../../_components/cards/OrdersCard';
class Orders extends React.Component {
    render() {
        return (
            <>
                <Header />
                <Container fluid={true}>
                    <section className="dashboard-page">
                        <h2 className="dashboard-heading">My Orders</h2>
                        <Row>
                            <Col md={{ size: "9" }}>
                                <OrdersCard />
                                <OrdersCard />
                            </Col>
                            <Col md={{ size: "3" }}>
                                <LeftMenu />
                            </Col>
                        </Row>
                    </section>
                </Container>
                <Footer />
            </>
        )
    }
}
export default Orders