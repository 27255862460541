import React from 'react';
import { Container } from 'reactstrap';
import Slider from "react-slick";
import WinnerCard from '../cards/WinnerCard';
class HomeWinners extends React.Component {
    render() {
        var settings = {
            arrows: true,
            dots: false,
            speed: 800,
            slidesToShow: 1,
            variableWidth: true,
            slidesToScroll: 1, centerMode: true,
        };
        return (
            <section className="home-winners">
                <Container fluid={true}>
                    <h2 className="home-heading mb-4">The Recent Proud Winners</h2>
                </Container>
                <Slider {...settings} className="home-winners-slider">
                    <div className="home-winner-padding">
                        <WinnerCard />
                    </div>
                    <div className="home-winner-padding">
                        <WinnerCard />
                    </div>
                    <div className="home-winner-padding">
                        <WinnerCard />
                    </div>
                    <div className="home-winner-padding">
                        <WinnerCard />
                    </div>
                </Slider>
            </section>
        )
    }
}
export default HomeWinners