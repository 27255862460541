import React from 'react';
import Header from '../../_components/header/header';
import Footer from '../../_components/footer/footer';
import MapContact from '../../_components/sections/MapContact';
import { Container } from 'reactstrap';
class HowWeWork extends React.Component {
    render() {
        return (
            <>
                <Header />
                <Container fluid={true}>
                    <h2 className="home-heading text-center mt-5">How we work!</h2>
                    <iframe title="video" className="how-we-work-iframe" src="https://www.youtube.com/embed/yAoLSRbwxL8"></iframe>
                </Container>
                <MapContact />
                <Footer />
            </>
        )
    }
}
export default HowWeWork