import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import CartLeft from '../../_components/cards/CartLeft';
import CartRight from '../../_components/cards/CartRight';
import Footer from '../../_components/footer/footer';
import Header from '../../_components/header/header';
class Cart extends React.Component {
    render() {
        return (
            <>
                <Header />
                <Container fluid={true}>
                    <section className="dashboard-page">
                        <h2 className="dashboard-heading">Cart</h2>
                        <Row>
                            <Col md={{ size: "9" }}>
                                <CartLeft />
                                <CartLeft />
                            </Col>
                            <Col md={{ size: "3" }}>
                                <CartRight />
                            </Col>
                        </Row>
                    </section>
                </Container>
                <Footer />
            </>
        )
    }
}
export default Cart