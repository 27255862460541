import React from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ProductCardFull from '../cards/ProductCardFull';
class FeaturedCampaigns extends React.Component {
    render() {
        return (
            <section className="featured-campaigns">
                <Container fluid={true}>
                    <Row>
                        <Col sm="8">
                            <h2 className="home-heading">Early bird Campaigns</h2>
                        </Col>
                        <Col sm="4" className="text-sm-right">
                            <h3 className="mt-3 mb-4"><NavLink to="">See All</NavLink></h3>
                        </Col>
                    </Row>
                    <ProductCardFull />
                    <ProductCardFull />
                </Container>
            </section>
        )
    }
}
export default FeaturedCampaigns