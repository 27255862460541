import React from 'react';
import Header from '../../_components/header/header';
import FeaturedCampaigns from '../../_components/sections/FeaturedCampaigns';
import HomeCategory from '../../_components/sections/HomeCategory';
import Footer from '../../_components/footer/footer';
import HomeWinners from '../../_components/sections/HomeWinners';
import HomeAllWinners from '../../_components/sections/HomeAllWinners';
class CampaignsList extends React.Component {
    render() {
        return (
            <>
                <Header
                    className="is-fixed"
                />
                <FeaturedCampaigns />
                <HomeCategory />
                <HomeWinners />
                <HomeAllWinners />
                <Footer />
            </>
        )
    }
}
export default CampaignsList