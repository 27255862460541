import React from 'react';
import { Container, Button, Row, Col } from 'reactstrap';
import ProductCardFull from '../cards/ProductCardFull';
class HomeCategory extends React.Component {
    render() {
        return (
            <section className="home-category">
                <Container fluid={true}>
                    <Row>
                        <Col md="6">
                            <h2 className="home-heading">All Active Campaigns</h2>
                        </Col>
                        <Col md="6">
                            <ul className="home-category-ul">
                                <li>
                                    <Button color="nothing" className="active">All</Button>
                                </li>
                                <li>
                                    <Button color="nothing">Gadgets</Button>
                                </li>
                                <li>
                                    <Button color="nothing">Gold</Button>
                                </li>
                                <li>
                                    <Button color="nothing">Cash</Button>
                                </li>
                                <li>
                                    <Button color="nothing">Automobiles</Button>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <ProductCardFull />
                    <ProductCardFull />
                    <ProductCardFull />
                    <ProductCardFull />
                    <ProductCardFull />
                </Container>
            </section>
        )
    }
}
export default HomeCategory