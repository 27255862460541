import React from 'react';
import { Row, Input, Col, Button } from 'reactstrap';
class PersonalFormCard extends React.Component {
    render() {
        return (
            <div className="personal-form-card">
                <h2 className="dashboard-heading">Personal Details</h2>
                <Row>
                    <Col md="6">
                        <div className="form-group">
                            <Input id="fname" type="text" placeholder="&nbsp;" />
                            <label htmlFor="fname" className="on-hover">First Name</label>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="form-group">
                            <Input id="lname" type="text" placeholder="&nbsp;" />
                            <label htmlFor="lname" className="on-hover">Last Name</label>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="form-group">
                            <Input id="gender" type="text" placeholder="&nbsp;" />
                            <label htmlFor="gender" className="on-hover">Gender</label>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="form-group">
                            <Input id="status" type="text" placeholder="&nbsp;" />
                            <label htmlFor="status" className="on-hover">Status</label>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="form-group">
                            <Input id="nationality" type="text" placeholder="&nbsp;" />
                            <label htmlFor="nationality" className="on-hover">Nationality</label>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="form-group">
                            <Input id="country" type="text" placeholder="&nbsp;" />
                            <label htmlFor="country" className="on-hover">Country of residence</label>
                        </div>
                    </Col>
                </Row>
                <h2 className="dashboard-heading mt-4">Contact Details</h2>
                <Row>
                    <Col md="2">
                        <div className="form-group">
                            <Input id="ccode" type="text" placeholder="&nbsp;" />
                            <label htmlFor="ccode" className="on-hover">Country Code</label>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="form-group">
                            <Input id="num" type="text" placeholder="&nbsp;" />
                            <label htmlFor="num" className="on-hover">Phone Number</label>
                        </div>
                    </Col>
                    <Col md="12" className="mt-4">
                        <Button color="red">Save details</Button>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default PersonalFormCard