import React from 'react';
import Header from '../../_components/header/header';
import Footer from '../../_components/footer/footer';
import MapContact from '../../_components/sections/MapContact';
class ContactUs extends React.Component {
    render() {
        return (
            <>
                <Header />
                <MapContact />
                <Footer />
            </>
        )
    }
}
export default ContactUs