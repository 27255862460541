import React from 'react';
class WinnerCard extends React.Component {
    render() {
        return (
            <div className="winner-card">
                <h5>Congratulations</h5>
                <h2>Rathnam Krishnamurthy</h2>
                <h5>on winning Audi Q7</h5>
                <div className="winner-padding">
                    <div className="profile-img" style={{ backgroundImage: "url('/images/profile.png')" }}></div>
                    <div className="gift-img" style={{ backgroundImage: "url('/images/car1.png')" }}></div>
                </div>
            </div>
        )
    }
}
export default WinnerCard