import React from 'react';
import { Button, Col, Row } from 'reactstrap';
class ActiveCouponsCard extends React.Component {
    render() {
        const { className } = this.props;
        return (
            <div className={"coupon-card-full " + className}>
                <div className="coupon-card-top">
                    <Row>
                        <Col md="4">
                            <img src="/images/car.png" alt="" className="img-fluid" />
                        </Col>
                        <Col md="8" className="d-flex flex-column justify-content-center">
                            <h2>Audi Q7</h2>
                            {className === "half" ? "" :
                                <>
                                    <p>12 December 2020</p>
                                    <p>03:00 PM</p>
                                </>
                            }
                        </Col>
                    </Row>
                </div>
                <div className="coupon-card-bottom">
                    <Row>
                        <Col md="6">
                            <h2>MOD 003 85 907</h2>
                            {className !== "half" ? "" :
                                <>
                                    <p>12 December 2020</p>
                                    <p>03:00 PM</p>
                                </>
                            }
                        </Col>
                        <Col md="6" className="text-md-right">
                            <Button color="white">
                                WATCH DRAW
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
export default ActiveCouponsCard